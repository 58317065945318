import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './App.css';
import Login from "./routes/Login";
import Home from "./routes/Home";
import CreateGame from "./routes/CreateGame";
import Game from "./routes/Game";
import WaitRoom from "./routes/WaitRoom";
import {DEFAULT_BACKEND_URL, SPIDERX} from "./constants";
import Cookie from "./models/Cookie";
import {ethers} from "ethers";
import {
  useContractRead,
} from "wagmi";

function App() {

  useContractRead({
    contractInterface: SPIDERX.abi,
    addressOrName: SPIDERX.address,
    functionName: 'balances',
    args: [Cookie.getPlayerHash()],
    watch: true,
    onSuccess(data) {
      if (data) {
        const newBalance = ethers.utils.formatEther(data.toString())
        console.log('balance: ', newBalance)
        if (newBalance != Cookie.getBalance().toString()) {
          Cookie.setBalance(parseFloat(newBalance))
        }
      }
    },
  })

  return (
    <div className="container">
    <Router>
      <Routes>
        <Route path="/" element={<Login/>}/>
        <Route path="/home" element={<Home/>}/>
        <Route path="/create-game" element={<CreateGame/>}/>
        <Route path="/game/:roomId" element={<Game/>}/>
        <Route path="/wait-room/:roomId" element={<WaitRoom/>}/>
      </Routes>
    </Router>
    </div>
  )
}

export default App;
