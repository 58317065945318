import React, { ChangeEvent, useContext, useEffect, useState } from "react"
import { useNavigate } from "react-router-dom";
import { Button, Form, InputGroup } from "react-bootstrap"
import UserContext from "../context/UserContext"
import GameServer, { PlayerJson, RoomJson } from "../models/GameServer";
import LocaleContext from "../context/LocaleContext";
import Cookie from "../models/Cookie";

function CreateGame() {
  const locale = useContext(LocaleContext)
  const user = useContext(UserContext)
  const [roomName, setRoomName] = useState<string>(user.nickname + '\'s room')
  const [maxPlayersCount, setMaxPlayersCount] = useState<number>(2)
  const [minutes, setMinutes] = useState<number>(process.env.NODE_ENV === 'development' ? 0.5 : 15)
  const [isSlowMode, setIsSlowMode] = useState<boolean>(false)
  const [description, setDescription] = useState<string | null>(null)
  const [betAmount, setBetAmount] = useState<number>(0)
  const navigate = useNavigate()
  const onRoomNameChangeInput = (e: React.BaseSyntheticEvent) => {
    setRoomName(e.target.value)
  }
  const onMaxPlayersCountChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setMaxPlayersCount(Number(e.target.value))
  }
  const onSlowModeChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    let isChecked = Number(e.target.value) === 1
    setMinutes(isChecked ? 30 : 15)
    setIsSlowMode(isChecked)
  }
  const onDescriptionChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value)
  }
  const onBetAmountChangeInput = (e: ChangeEvent<HTMLInputElement>) => {
    setBetAmount(Number(e.target.value))
  }
  const createRoomBtn = () => {
    GameServer.createRoom(roomName, user.hash, maxPlayersCount, minutes, description, betAmount)
  }
  const goBackBtn = () => {
    navigate('/home')
  }
  const onServerPutCreateRoom = (playerJson: PlayerJson, roomJson: RoomJson) => {
    if (user.hash !== playerJson.hash) throw new Error("Something went wrong")
    user.setBalance(playerJson.balance)
    navigate('/wait-room/' + roomJson.id)
  }
  const onServerPutCreateRoomError = (errorMessage: string) => {
    alert(locale.t(errorMessage))
  }
  useEffect(() => {
    GameServer.subscribeOnServerPutCreateRoom(onServerPutCreateRoom)
    GameServer.subscribeOnServerPutCreateRoomError(onServerPutCreateRoomError)
    return () => {
      GameServer.unsubscribeOnServerPutCreateRoom()
      GameServer.unsubscribeOnServerPutCreateRoomError()
    }
  }, [])
  return (
    <Form>
      <h3 className="mt-3">{locale.t('Create game')}</h3>
      <Form.Group className="mb-3 d-none" controlId="formRoomName">
        <Form.Label>{locale.t('Room name')}</Form.Label>
        <Form.Control type="text" placeholder={locale.t('Room name')} value={roomName} onChange={onRoomNameChangeInput} />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formMaxPlayersCount">
        <Form.Label className="me-2" >{locale.t('Players:')}</Form.Label >
        <Form.Check
          inline
          label="2"
          name="group1"
          type="radio"
          id={`inline-radio-1`}
          value={2}
          checked={maxPlayersCount === 2}
          onChange={onMaxPlayersCountChangeInput}
        />
        <Form.Check
          inline
          label="3"
          name="group1"
          type="radio"
          id={`inline-radio-2`}
          value={3}
          checked={maxPlayersCount === 3}
          onChange={onMaxPlayersCountChangeInput}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formIsSlowMode">
        <Form.Label className="me-2" >{locale.t('Mode:')}</Form.Label >
        <Form.Check
          inline
          label={locale.t('Fast')}
          name="group-mode"
          type="radio"
          id={`inline-radio-mode-1`}
          value={0}
          checked={isSlowMode === false}
          onChange={onSlowModeChangeInput}
        />
        <Form.Check
          inline
          label={locale.t('Slow')}
          name="group-mode"
          type="radio"
          id={`inline-radio-mode-2`}
          value={1}
          checked={isSlowMode === true}
          onChange={onSlowModeChangeInput}
        />
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBetAmount">
        <Form.Label>{locale.t('Bet amount')} ({locale.t('Your balance')} ${Cookie.getBalance()}):</Form.Label>
        <InputGroup className="mb-3">
          <InputGroup.Text>$</InputGroup.Text>
          <Form.Control type="number" placeholder={locale.t('Bet amount')} min={0} max={Cookie.getBalance()} defaultValue={0} onChange={onBetAmountChangeInput}/>
        </InputGroup>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formDescription">
        <Form.Label>{locale.t('Description')}</Form.Label>
        <Form.Control type="textarea" placeholder={locale.t('Description')} defaultValue={''} onChange={onDescriptionChangeInput} />
      </Form.Group>
      <div className="d-grid gap-2">
        <Button variant="primary" size="sm" role={"button"} onClick={createRoomBtn}>{locale.t('Create room')}</Button>
        <Button variant="secondary" size="sm" role={"button"} onClick={goBackBtn}>{locale.t('go back')}</Button>
      </div>
    </Form>
  )
}

export default CreateGame