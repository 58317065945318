import { ChangeEvent, useContext, useEffect, useState } from "react";
import { Button, Form, Spinner, Table } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import LocaleContext from "../context/LocaleContext";
import UserContext from "../context/UserContext";
import RoomStatus from "../enum/RoomStatus";
import GameServer, { PlayerJson, RoomJson } from "../models/GameServer";

function WaitRoom() {

  const { roomId } = useParams<"roomId">();
  const user = useContext(UserContext)
  const locale = useContext(LocaleContext)
  const [room, setRoom] = useState<RoomJson | undefined>()
  const [readinessCheck, setReadinessCheck] = useState<boolean>(false)
  const navigate = useNavigate()
  const leaveRoomBtn = () => {
    if (roomId === undefined) return
    GameServer.leaveRoom(roomId, user.hash)
  }
  const onServerPutJoinedNewPlayer = (playerJson: PlayerJson, roomJson: RoomJson) => {
    if (roomJson.id !== roomId) return
    setRoom(roomJson)
  }
  const onServerPutLeavedPlayer = (playerJson: PlayerJson, roomJson: RoomJson) => {
    if (roomJson.id !== roomId) return
    setRoom(roomJson)
    if (playerJson.hash !== user.hash) return
    navigate('/home')
  }
  const onServerPutRoomPlay = (roomJson: RoomJson) => {
    if (roomJson.id !== roomId) return
    if (roomJson.status !== RoomStatus.Playing) return
    setRoom(roomJson)
    navigate('/game/' + roomJson.id)
  }
  const onServerPutJoinToRoomError = (errorMessage: string) => {
    alert(locale.t(errorMessage))
  }
  const onServerPutMessageReadinessError = (errorMessage: string) => {
    alert(locale.t(errorMessage))
  } 
  const onServerPutRoom = (r: RoomJson) => {
    setRoom(r)
    const playerReadiness = r.playersReadiness.find(pr => pr.nickname === user.nickname)
    if (playerReadiness === undefined) return
    setReadinessCheck(playerReadiness.isReady)
  }
  const onReadinessCheckChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (roomId === undefined) return
    setReadinessCheck(event.target.checked)
    GameServer.messageReadiness(roomId, user.hash, event.target.checked)
  }
  const onServerPutRoomGameOver = (roomJson: RoomJson, playerJson: PlayerJson, rightWord: string, winner: PlayerJson | null) => {
    alert(locale.t("Room has been closed"))
    navigate('/home')
  }
  const onServerPutRoomError = (errorMessage: string) => {
    alert(locale.t(errorMessage))
    navigate('/home')
  }

  useEffect(() => {
    GameServer.subscribeOnServerPutJoinedNewPlayer(onServerPutJoinedNewPlayer)
    GameServer.subscribeOnServerPutLeavedPlayer(onServerPutLeavedPlayer)
    GameServer.subscribeOnServerPutRoomPlay(onServerPutRoomPlay)
    GameServer.subscribeOnServerPutJoinToRoomError(onServerPutJoinToRoomError)
    GameServer.subscribeOnServerPutMessageReadinessError(onServerPutMessageReadinessError)
    GameServer.subscribeOnServerPutRoom(onServerPutRoom)
    GameServer.subscribeOnServerPutRoomError(onServerPutRoomError)
    GameServer.subscribeOnServerPutRoomGameOver(onServerPutRoomGameOver)
    if (roomId) GameServer.getRoom(roomId)
    return () => {
      GameServer.unsubscribeOnServerPutJoinedNewPlayer()
      GameServer.unsubscribeOnServerPutLeavedPlayer()
      GameServer.unsubscribeOnServerPutRoomPlay()
      GameServer.unsubscribeOnServerPutJoinToRoomError()
      GameServer.unsubscribeOnServerPutMessageReadinessError()
      GameServer.unsubscribeOnServerPutRoom()
      GameServer.unsubscribeOnServerPutRoomError()
      GameServer.unsubscribeOnServerPutRoomGameOver()
    }
  }, [])

  return (
    <div className="container my-3">
      {room ? [
      <div className="mb-3 text-center">
        <Spinner animation="border" variant="success" />
        <p>{locale.t('Waiting for players')} {room.playersCountCurrent}/{room.playersCountMax}</p>
        <Table borderless>
          <tbody>
            {room.playersReadiness.map(playerReadiness => {
              return (
                <tr key={Math.random()}>
                  <td>{playerReadiness.nickname} - <span className={"p-1 px-2 border rounded-pill border-2 border-rounded border-" + (playerReadiness.isReady ? "success" : "danger")}>{playerReadiness.isReady ? locale.t('ready') : locale.t('waiting')}</span></td>
                </tr>
              )
            })}
          </tbody>
        </Table>
        <Form>
          <Form.Check 
            type="switch"
            id="custom-switch"
            label={locale.t('I am ready')}
            className="col-4 offset-4 col-lg-2 offset-lg-5"
            checked={readinessCheck}
            onChange={onReadinessCheckChange}
          />
        </Form>
      </div>,
      <br />,
      <div className="d-grid gap-2">
          <Button variant="secondary" size="sm" role={"button"} onClick={leaveRoomBtn}>{locale.t('Leave')}</Button>
      </div>
      ] : (
        <p>{locale.t('Loading')}</p>
      )}
    </div>
  )

}

export default WaitRoom