import { useContext } from "react"
import LocaleLanguages from "../enum/LocaleLanguages"
import UserContext from "../context/UserContext"
import LetterStatus from "../enum/LetterStatus"
import GameServer, { PlayerWordleJson, RoomJson } from "../models/GameServer"
import Letter, { LetterStatusEnum } from "./Letter"

function Keyboard(props: { room: RoomJson, playerWordle: PlayerWordleJson }) {
  const user = useContext(UserContext)
  const getKeyRows = () => {
    switch (props.playerWordle.wordle.localeLanguage) {
      case LocaleLanguages.English:
        return [
          ['q', 'w', 'e', 'r', 't', 'y', 'u', 'i', 'o', 'p'],
          ['a', 's', 'd', 'f', 'g', 'h', 'j', 'k', 'l'],
          ['⌫', 'z', 'x', 'c', 'v', 'b', 'n', 'm', '↵'],
        ]
      case LocaleLanguages.Russian:
        return [
          ['й', 'ц', 'у', 'к', 'е', 'ё', 'н', 'г', 'ш', 'щ', 'з', 'х', 'ъ'],
          ['ф', 'ы', 'в', 'а', 'п', 'р', 'о', 'л', 'д', 'ж', 'э'],
          ['⌫', 'я', 'ч', 'с', 'м', 'и', 'т', 'ь', 'б', 'ю', '↵'],
        ] 
    }
    
  }
  const getLetterStatus = (symbol: string) => {
    let status = LetterStatusEnum.Typing
    for (let i = 0; i < props.playerWordle.wordle.words.length; i++) {
      const triedWord = props.playerWordle.wordle.words[i];
      for (let j = 0; j < triedWord.letterStatuses.length; j++) {
        const letterStatus = triedWord.letterStatuses[j];
        const letterSymbol = triedWord.word[j]
        if (letterSymbol !== symbol) continue
        if (letterStatus === LetterStatus.Right) return LetterStatusEnum.Right
        if (letterStatus === LetterStatus.PartiallyRight) status = LetterStatusEnum.PartialRight
        if (letterStatus === LetterStatus.Wrong && status !== LetterStatusEnum.PartialRight) status = LetterStatusEnum.Wrong
      }
    }
    return status
  }
  const onKeyClick = (symbol: string) => {
    GameServer.enterKey(props.room.id, user.hash, symbol)
  }
  return (
    <div className="row user-select-none">
      {getKeyRows().map(keyRow => {
        return (
          <div key={Math.random()} className="d-flex justify-content-center">
            {keyRow.map(key => {
              let letter = getLetterStatus(key)
              return (
                <Letter key={key} onClick={() => { onKeyClick(key) }} symbol={key} status={letter} showLetters={true} isKeyboard={true} />
              )
            })}
          </div>
        )
      })}
    </div>
  )
}

export default Keyboard