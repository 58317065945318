export enum LetterStatusEnum {
  Wrong = "0",
  Right = "1",
  PartialRight = "2",
  Typing = "3"
}

function Letter(props: { symbol: string, status: LetterStatusEnum, showLetters: boolean, isKeyboard: boolean, onClick?: () => void }) {
  const getBgColor = () => {
    switch (props.status) {
      case LetterStatusEnum.PartialRight:
        return "bg-warning"
      case LetterStatusEnum.Right:
        return "bg-success"
      case LetterStatusEnum.Typing:
        return ""
      case LetterStatusEnum.Wrong:
        return "bg-secondary"
    }
  }
  const getTextColor = () => {
    return props.status === LetterStatusEnum.Typing ? "text-black" : "text-white"
  }
  const squareSize = props.showLetters ? '3rem' : '1rem'
  return <div
    role={props.isKeyboard ? "button" : undefined}
    style={{ width: squareSize, height: squareSize }}
    className={"h2 mx-1 "+getTextColor()+" border rounded "+getBgColor()+" text-uppercase text-center d-flex flex-column justify-content-center"}
    onClick={() => { if (props.onClick) props.onClick() }}
  >
    <span className="align-middle">{props.symbol}</span>
  </div>
}

export default Letter