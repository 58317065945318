import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {WagmiConfig, createClient, Chain, configureChains} from 'wagmi'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc';

const bnbSmartChain: Chain = {
    id: 97,
    name: 'BNB Smart Chain',
    network: 'BSC',
    nativeCurrency: {
        decimals: 18,
        name: 'BNB',
        symbol: 'BNB',
    },
    rpcUrls: {
        default: 'https://rpc.ankr.com/bsc_testnet_chapel',
    },
    blockExplorers: {
        default: { name: 'BscScan', url: 'https://testnet.bscscan.com' },
    },
    testnet: true,
};

const { provider } = configureChains(
    [bnbSmartChain],
    [
        jsonRpcProvider({
            rpc: (chain) => {
                if (chain.id !== bnbSmartChain.id) return null;
                return { http: chain.rpcUrls.default };
            },
        }),
    ],
);

const client = createClient({
    autoConnect: true,
    provider: provider,
})

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    <WagmiConfig client={client}>
        <App />
    </WagmiConfig>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
