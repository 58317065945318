import { useEffect, useState } from "react"

function Timer(props: { deadline: number }) {
  const [timerText, setTimerText] = useState<string>()
  const getSeconds = () => {
    const seconds = (props.deadline - Date.now()) / 1000
    return seconds >= 0 ? seconds : 0
  }
  const formatToXX = (x: number) => {
    let xStr = Math.floor(x).toString()
    if (xStr.length === 1) {
      return '0' + xStr
    }
    return xStr
  }
  const getTimerText = () => {
    const seconds = getSeconds()
    const m = seconds / 60
    const s = seconds % 60
    return formatToXX(m) + ':' + formatToXX(s)
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setTimerText(getTimerText())
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  }, [])
  return (
    <>{timerText}</>
  )
}

export default Timer