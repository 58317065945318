import { useContext, useEffect, useState } from 'react';
import Board from '../components/Board';
import Keyboard from '../components/Keyboard';
import UserContext from '../context/UserContext';
import GameServer, { PlayerJson, PlayerWordleJson, RoomJson } from '../models/GameServer';
import RoomStatus from '../enum/RoomStatus';
import { useNavigate, useParams } from 'react-router-dom';
import Timer from '../components/Timer';
import LocaleContext from '../context/LocaleContext';
import Cookie from "../models/Cookie";

function Game() {

  const { roomId } = useParams<"roomId">();
  const user = useContext(UserContext)
  const [room, setRoom] = useState<RoomJson>()
  const locale = useContext(LocaleContext)
  const navigate = useNavigate()
  const [myWordle, setMyWordle] = useState<PlayerWordleJson>()
  const [enemiesWordles, setEnemiesWordles] = useState<PlayerWordleJson[]>()

  const leaveRoomBtn = () => {
    if (roomId === undefined) return
    GameServer.leaveRoom(roomId, user.hash)
  }
  const onServerPutRoomGameOver = (roomJson: RoomJson, playerJson: PlayerJson, rightWord: string, winner: PlayerJson | null) => {
    if (roomJson.id !== roomId) return
    if (roomJson.status === RoomStatus.GameOver) {
      if (winner) {
        if (winner.hash === user.hash) {
          alert(locale.t("You win") + " $" + (roomJson.bet ? roomJson.bet.bettedAmount : 0) + ". " + locale.t("Right word") + ": " + rightWord + ".")
        } else {
          alert(locale.t("You lose") + " $" + (roomJson.bet ? roomJson.bet.betAmount : 0) + ". " + locale.t("Right word") + ": " + rightWord + ".")
        }
      } else {
        alert(locale.t("Draw") + ". " + locale.t("Right word") + ": " + rightWord + ".")
      }
      user.setBalance(playerJson.balance)
      navigate('/home')
    }
  }
  const onServerPutPlayersWordles = (playersWordles: PlayerWordleJson[]) => {
    const playerWordle = playersWordles.find(playerWordle => playerWordle.player.hash === user.hash)
    const enemiesWordles = playersWordles.filter(pw => pw.player.hash !== user.hash)
    if (playerWordle === undefined) return
    setMyWordle(playerWordle)
    setEnemiesWordles(enemiesWordles)
  }
  const onServerPutLeavedPlayer = (playerJson: PlayerJson, roomJson: RoomJson) => {
    if (roomJson.id !== roomId) return
    if (playerJson.hash !== user.hash) return
    navigate('/home')
  }
  const onServerPutRoom = (roomJson: RoomJson) => {
    setRoom(roomJson)
    GameServer.getPlayersWordles(roomJson.id, user.hash)
  }
  const onServerPutRoomError = (errorMessage: string) => {
    alert(locale.t(errorMessage))
  }

  useEffect(() => {
    GameServer.subscribeOnServerPutRoomGameOver(onServerPutRoomGameOver)
    GameServer.subscribeOnServerPutPlayersWordles(onServerPutPlayersWordles)
    GameServer.subscribeOnServerPutLeavedPlayer(onServerPutLeavedPlayer)
    GameServer.subscribeOnServerPutRoom(onServerPutRoom)
    GameServer.subscribeOnServerPutRoomError(onServerPutRoomError)
    if (roomId) GameServer.getRoom(roomId)
    return () => {
      GameServer.unsubscribeOnServerPutRoomGameOver()
      GameServer.unsubscribeOnServerPutPlayersWordles()
      GameServer.unsubscribeOnServerPutLeavedPlayer()
      GameServer.unsubscribeOnServerPutRoom()
      GameServer.unsubscribeOnServerPutRoomError()
    }
  }, [])

  return (
    room === undefined
    ?
    <><p>{locale.t('Loading')}</p></>
    :
    <>
      <div className="row">
        <div className="col">
          <div className="row">
            <div className="col">
              <div>{locale.t("Fund")}: <span className='text-warning'>${room.bet ? room.bet.bettedAmount : 0}</span></div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <div>{locale.t("My bet")}: <span className='text-warning'>${room.bet ? room.bet.betAmount : 0}</span></div>
            </div>
          </div>
        </div>
        <div className="col">
          <div className="row">
            <div className="col m-2">
              <button type="button" className="btn btn-secondary w-100" role={"button"} onClick={() => leaveRoomBtn()}>{locale.t("Leave")}</button>
            </div>
          </div>
          <div className="row">
            <div className="col m-2">
              <div className='text-center'><span className='text-warning'>{room.deadline ? <Timer deadline={room.deadline}/> : ''}</span></div>
            </div>
          </div>
        </div>
      </div>
      <div className="row my-4">
        <div className="
          col-lg-6 offset-lg-3
          col-md-8 offset-md-2
          col-sm-10 offset-sm-1
          col-xs-12
        ">
          <div className="row">
            {room && myWordle ? 
              <div className="
                col-xl-5 offset-xl-2
                col-lg-6 offset-lg-1
                col-md-6 offset-md-1
                col-sm-6 offset-sm-1
                col-8
              ">
                <p>{myWordle.player.nickname} <span className='text-success'>{myWordle.wordle.greenLetterCounter.current}</span>/<span className='text-success'>{myWordle.wordle.greenLetterCounter.max}</span></p>
                <Board room={room} playerWordle={myWordle} isEnemy={false}/>
              </div>
              :
              <></>
            }
            <div className="
              col-xl-3
              col-lg-4
              col-md-4
              col-sm-4
              col-4
            ">
              {room && enemiesWordles ?
                enemiesWordles.map(enemyWordle => {
                  return <div key={enemyWordle.player.hash} className="row mb-1">
                    <div className="col">
                      <p>{enemyWordle.player.nickname} <span className='text-success'>{enemyWordle.wordle.greenLetterCounter.current}</span>/<span className='text-success'>{enemyWordle.wordle.greenLetterCounter.max}</span></p>
                      <Board room={room} playerWordle={enemyWordle} isEnemy={true}/>
                    </div>
                  </div>
                })
                :
                <></>
              }
            </div>
          </div>
        </div>
      </div>
      {room && myWordle ?
        <div className="row my-4">
          <div className="col">
            <Keyboard room={room} playerWordle={myWordle}/>
          </div>
        </div>
        :
        <></>
      }
    </>
  );

}

export default Game