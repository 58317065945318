import gsap from "gsap"
import { useContext, useEffect, useState } from "react"
import LetterStatus from "../enum/LetterStatus"
import GameServer, { PlayerWordleJson, RoomJson } from "../models/GameServer"
import Letter, { LetterStatusEnum } from "./Letter"

function Board(props: { room: RoomJson, playerWordle: PlayerWordleJson, isEnemy: boolean }) {
  const getElementId = (rowNum: number) => {
    return 'word_' + props.room.id + '_' + props.playerWordle.player.hash + '_row_' + rowNum
  }
  const letterStatus2LetterStatusEnum = (letterStatus: LetterStatus): LetterStatusEnum => {
    switch (letterStatus) {
      case LetterStatus.Right:
        return LetterStatusEnum.Right
      case LetterStatus.Wrong:
        return LetterStatusEnum.Wrong
      case LetterStatus.PartiallyRight:
        return LetterStatusEnum.PartialRight
      default:
        return LetterStatusEnum.Typing
    }
  }
  const onServerPutWrongWordTyped = (roomId: string, hash: string, rowNum: number) => {
    if (props.room.id !== roomId) return
    if (props.playerWordle.player.hash !== hash) return
    const selector = '#' + getElementId(rowNum) + ' > div > div'
    gsap.timeline()
      .to(selector, { duration: 0.2, backgroundColor: 'red' })
      .to(selector, { duration: 0.2, backgroundColor: 'white' })
      .repeat(1)
  }
  useEffect(() => {
    GameServer.subscribeOnServerPutWrongWordTyped(onServerPutWrongWordTyped)
    return () =>  {
      GameServer.unsubscribeOnServerPutWrongWordTyped()
    }
  }, [])
  return (
    <>
    {props.playerWordle.wordle.words.map((wordleWord, rowNum) => {
      return <div key={'r-' + props.room.id + '-' + props.playerWordle.player.hash + '-' + rowNum} id={getElementId(rowNum)} className="row">
        <div className="d-flex flex-row">
          {wordleWord.letterStatuses.map((letterStatus, i) => {
            return <Letter key={i} symbol={props.isEnemy ? ((letterStatus === LetterStatus.Typing && wordleWord.word[i]) ? '•' : '') : wordleWord.word[i]} status={letterStatus2LetterStatusEnum(letterStatus)} showLetters={!props.isEnemy} isKeyboard={false} />
          })}
        </div>
      </div>
    })}
    </>
  )
}

export default Board